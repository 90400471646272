import React from 'react';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
// nodejs library that concatenates classes
import classNames from 'classnames';
// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';

import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';

import screen1 from 'assets/img/screen1.jpg';
import screen2 from 'assets/img/screen2.jpg';
import screen3 from 'assets/img/screen3.gif';
import screen4 from 'assets/img/screen4.jpg';
import screen5 from 'assets/img/screen5.gif';
import screen6 from 'assets/img/screen6.jpg';
import eimage from 'assets/img/E.svg';
import pegi3image from 'assets/img/pegi3.jpg';
//import styles from 'assets/jss/material-kit-react/views/landingPageSections/productStyle.js';
//import styles from 'assets/jss/material-kit-react/views/landingPageSections/teamStyle.js';

const useStyles = makeStyles({});

export default function LandingBody() {
  const classes = useStyles();
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRounded,
    classes.imgFluid
  );

  const eStyle = {
    margin: '20px',
    height: '150px',
  };

  const imgStyle = {
    width: '100%',
  };

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <Card plain>
            <h2 className={classes.cardTitle}>Something for Everyone</h2>

            <CardBody>
              <img
                style={imgStyle}
                src={screen1}
                alt='Game Screenshot of Title Screen'
                className={imageClasses}
              />
              <p className={classes.description}>
                With <strong>four unique difficulty settings</strong>, Match
                Caixa has something for everyone. If you're a casual player
                looking to pass a few idle minutes relaxing try{' '}
                <strong>Chill</strong> mode. But if you have a need for speed
                try the <strong>Loco</strong> setting. Yeah that means "crazy"
                in Spanish. Good luck with that my friend. Don't sweat it
                though, you have <strong>Basic</strong> and{' '}
                <strong>Challenge</strong> to master first.
                <br />
                Match Caixa is rated E for Everyone.
              </p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <Card plain>
            <h2 className={classes.cardTitle}>Deceptively Simple</h2>

            <CardBody>
              <img
                style={imgStyle}
                src={screen2}
                alt='Game Screenshot of Gameplay'
                className={imageClasses}
              />
              <p className={classes.description}>
                <strong>
                  Just tap the small, swipe, and drag across the colored tiles
                </strong>{' '}
                to cycle through their colors.{' '}
                <strong>Match the target color</strong> up top to progress
                through the levels. That's all there is to it. Oh, well, unless
                you count the fact that the pistons which change the colors
                slowly reset on their own and when they do - they spring back up
                and cycle the tile above them to the next color! This isn't a
                problem at first, but as you advance the pistons move faster and
                faster giving your fingers a run for their win.
              </p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <Card plain>
            <h2 className={classes.cardTitle}>What's Your Favorite Color?</h2>

            <CardBody>
              <img
                style={imgStyle}
                src={screen3}
                alt='Game Screenshot of Color Pack Selection Screen'
                className={imageClasses}
              />
              <p className={classes.description}>
                <strong>Match Caixa</strong> is all about the simple beauty of
                color, patterns, and sound. You can choose from over a dozen
                different color palettes to play with. Keep in mind that the
                more colors there are, the longer it will take to cycle through
                them. During the Early Access Beta all color palettes will be
                unlocked by default, once the game launches some of them will be
                locked and the only way to open them up will be to complete
                various <strong>Google Play Achievements</strong>.
              </p>
            </CardBody>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={12} md={6}>
          <Card plain>
            <h2 className={classes.cardTitle}>Challenge Yourself</h2>

            <CardBody>
              <img
                style={imgStyle}
                src={screen4}
                alt='Game Screenshot of Game Over Screen'
                className={imageClasses}
              />
              <p className={classes.description}>
                Keep track of your best score on each level of play, number of
                colors, and puzzle seed. Track your improvement over time.{' '}
                <br />
                <strong>Google Play Leaderboards</strong> are coming soon so
                practice up!
              </p>
            </CardBody>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={12} md={6}>
          <Card plain>
            <h2 className={classes.cardTitle}>What's Your Favorite Tune?</h2>
            <CardBody>
              <img
                style={imgStyle}
                src={screen6}
                alt='Game Screenshot of Color Pack Selection Screen'
                className={imageClasses}
              />
              <p className={classes.description}>
                I mentioned both color and sound - here you have it. There are
                also sound packs being added to the Caixa. You will be able to
                play with your choice of nylon string guitars, synth horns,
                drums, clarinet, and more! Sound packs are inspired by some of
                my favorite musicians. Let me know if you'd like one added.
                <br />
                During the Early Access Beta all sounds will be unlocked by
                default, but the final version will challenge you with a number
                of <strong>Google Play Achievements</strong> and each will
                unlock something new.
              </p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <Card plain>
            <h2 className={classes.cardTitle}>Have it Your Way</h2>

            <CardBody>
              <img
                style={imgStyle}
                src={screen5}
                alt='Game Screenshot of Settings Screen'
                className={imageClasses}
              />
              <p className={classes.description}>
                <strong>Match Caixa</strong> lets you adjust settings to suit
                your play style and environment. Keep the sound on for relaxing
                tones and that subtle "lock in" sound when you've matched a
                color. Use vibration to "feel" the tiles as you swipe your
                finger over them. If you're playing on an older phone or are
                just distracted by the glow effects you can turn those off too.
                <br />
                <br />
                Use <strong>seeded puzzles</strong> to train and compete on a
                (non-random) puzzle set. You can use this feature to challenge a
                friend as well - if you both use the same random seed word you
                will both have exactly the same puzzles every time you play.
                This means you can keep refining your moves to maximize your
                bonus and minimize the time it takes to clear a level. It's a
                new kind of speed run.
              </p>
            </CardBody>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={12} md={12}>
          <a href='https://play.google.com/store/apps/details?id=com.jrvisuallsllc.matchcaixa&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
            <img
              alt='Get it on Google Play'
              src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'
              width='300'
            />
          </a>
          <Card plain>
            <h2 className={classes.cardTitle}>Rated E for Everyone</h2>
            <CardBody>
              <p className={classes.description}>
                We can't say that this game is for kids because of technical
                issues related to how the app handles analytics, anonymous
                tracking, and over the air updates (details in our privacy
                policy). We can, however say that the ESRB rated it E for
                Everyone and PEGI rated it acceptable for 3 year olds - so
                there's that.
              </p>
              <img src={eimage} alt='Rated E for Everyone' style={eStyle} />
              <img src={pegi3image} alt='Rated PEGI 3' style={eStyle} />
              <br />
              <br />
              Google Play and the Google Play logo are trademarks of Google LLC.
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
