import { container, title } from 'assets/jss/material-kit-react.js';

const mainRaisedBase = {
  margin: '-60px 30px 0px',
  borderRadius: '6px',
  boxShadow:
    '0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)',
};
const landingPageStyle = {
  container: {
    zIndex: '12',
    color: '#FFFFFF',
    ...container,
  },
  title: {
    ...title,
    display: 'inline-block',
    position: 'relative',
    marginTop: '30px',
    minHeight: '32px',
    color: '#FFFFFF',
    textDecoration: 'none',
  },
  subtitle: {
    fontSize: '1.313rem',
    maxWidth: '500px',
    margin: '10px auto 0',
  },
  main: {
    background: '#FFFFFF',
    position: 'relative',
    zIndex: '3',
  },

  mainRaised: {
    ...mainRaisedBase,
    '@media (max-width: 400px)': {
      borderRadius: '0px',
      margin: '0px 12px 0px',
      boxShadow: 'none',
    },
    '@media (min-width: 576px)': {},
    '@media (min-width: 768px)': {},
    '@media (min-width: 992px)': {},
    '@media (min-width: 1200px)': {},
  },
};

export default landingPageStyle;
