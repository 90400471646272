import { createShaderCanvas } from 'react-shader-canvas';
import cm from './common';

const shader = (props) =>
  cm +
  `
  #ifdef GL_ES
precision mediump float;
#endif
uniform vec2 u_resolution;
uniform float u_time;

/* Usar rutina que usa sin para pintar lineas */

float lines(vec2 st, float thick) {
  float d = distance(st, vec2(0.5));
	float val = sin(snoise(st)*5.+u_time) + sin(st.x * st.y);
	// if val is 1 draw line
	return smoothstep(1. - thick, 1., val);
}

float grid(vec2 st, float thick) {
	float lh = lines(st, thick);
	float lv = lines(st * rotate2d(PI/2.), thick);
	return lv + lh;
}

void main() {
	vec2 uv= gl_FragCoord.xy / u_resolution.xy;
  vec2 ouv = uv;
  float c = circle(uv, 0.5);
  uv = uv * 100.;
	float l = grid(uv, 0.5);
	vec3 finalColor = vec3(sin(ouv.x+ouv.y), 0.5, 0.8*sin(uv.x*u_time))*l*c;
	gl_FragColor = vec4(finalColor, 1.0);
}
  
`;

const ShaderComponent = createShaderCanvas(shader);

export default ShaderComponent;
