import React from 'react';
// nodejs library that concatenates classes
import classNames from 'classnames';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

// @material-ui/icons

// core components
import Footer from 'components/Footer/Footer.js';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
// import Button from 'components/CustomButtons/Button.js';
import Parallax from 'components/Parallax/Parallax.js';

import styles from 'assets/jss/material-kit-react/views/landingPage.js';

// Sections for this page
import LandingBody from 'views/LandingBody.js';
import bgimage from 'assets/img/mcbg.jpg';
import logoimage from 'assets/img/caixa_logo_chips_200.png';
import eimage from 'assets/img/E.svg';
import ShaderComponent from 'components/Shader/Shader';
const useStyles = makeStyles(styles);

export const LandingPage = (props) => {
  const classes = useStyles();
  // const { ...rest } = props;

  // const state = {
  //   timeSync: false,
  // };
  // const Shader = (
  //   <ShaderComponent
  //     style={{ width: '100%', height: '100vh' }}
  //     id='bg-shader'
  //     timeSync={state.timeSync}
  //   />
  // );

  const eStyle = {
    marginLeft: '20px',
    marginBottom: '14px',
    height: '50px',
  };

  const videoWrapper = {
    marginTop: '40px',
    position: 'relative',
    paddingBottom: '56.25%' /* 16:9 */,
    paddingTop: '25px',
    height: '0',
  };
  const iframeStyle = {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
  };

  const darkText = {
    color: '#000f1c',
  };
  const extraPadDark = {
    ...darkText,
    marginLeft: '10px',
    marginRight: '10px',
  };

  return (
    <div>
      <Parallax filter image={bgimage}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <img src={logoimage} alt='Match Caixa logo' />
              <h4>
                Match Caixa is a deceptively simple color changing action
                puzzle. It is currently in early access at the Google Play
                store. This relaxing puzzler generates soothing tones as you tap
                your screen to match the target color.
              </h4>
              <img src={eimage} alt='Rated E for Everyone' style={eStyle} />
              <a href='https://play.google.com/store/apps/details?id=com.jrvisuallsllc.matchcaixa&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                <img
                  alt='Get it on Google Play'
                  src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'
                  width='200'
                />
              </a>
              <br />
              Available now on the Google Play store in Early Access.
              <br />
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <h1 style={darkText}>
                What makes Match Caixa special?
                <br />
                Take a look.
              </h1>
              <div className={classes.description} style={extraPadDark}>
                Match Caixa is independently designed and funded - it is the
                passion project of a single designer/developer.
                <br />
                This is the game I've always wanted to play on my phone or
                tablet.
                <br />
                Over 15 years in the making,
                <br />
                Match Caixa hits that sweet spot between a casual puzzle and an
                action challenge.
                <br />
                Sure it costs a couple of bucks, but there are not now, and
                never will be any ads or in-game purchases.
                <br />
                All of the unlocks are avilable via Google Play Achievements.
                <br />
                <br />
                Oh, and pro-tip:{' '}
                <em>
                  If you're a fast texter - you're going to be aces with Caixa.
                </em>
              </div>
            </GridItem>

            <GridItem xs={12} sm={12} md={12}>
              <div style={videoWrapper}>
                <br />
                <iframe
                  style={iframeStyle}
                  title='YouTube Video of Match Caixa gameplay'
                  src='https://www.youtube-nocookie.com/embed/kD2ODikhpBo'
                  frameborder='0'
                  allow='autoplay;'
                  allowfullscreen
                ></iframe>
              </div>
            </GridItem>
            <LandingBody />
            <GridItem xs={12} sm={12} md={12}>
              <h1 style={darkText}>Tutorial: The Basics of How to Play</h1>
              <div className={classes.description} style={extraPadDark}>
                Jose Rodriguez (that's me) also known as JoskerDu walks us
                through the basics (and a pro tip or two) of how to play Match
                Caixa in this super chill 3 minute video.
              </div>
              <div style={videoWrapper}>
                <br />
                <iframe
                  style={iframeStyle}
                  title='YouTube Video of Match Caixa gameplay'
                  src='https://www.youtube-nocookie.com/embed/1MSai5muz_c'
                  frameborder='0'
                  allow='autoplay;'
                  allowfullscreen
                ></iframe>
              </div>
            </GridItem>
          </GridContainer>
          <br />
        </div>
      </div>
      <Footer />
    </div>
  );
};
